import Image from "next/image";
import Button from "../ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";
import ImagePreloader from "../ImagePreloader";

interface HarleyStreetFaceliftHeroData {
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  image: {
    alt?: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
  button?: {
    href: string;
    label: string;
  };
}

interface HarleyStreetFaceliftHeroProps {
  data?: HarleyStreetFaceliftHeroData;
}

export const fields = [
  "heading.text",
  "heading.title",

  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",

  "button.href",
  "button.label",

  "text.text",
] as const;

export default function HarleyStreetFaceliftHero({
  data,
}: HarleyStreetFaceliftHeroProps) {
  return (
    <>
    {data?.image?.image_file?.image?.id && data?.image?.image_file?.image?.filename_download && (
      <ImagePreloader
        imageId={data.image.image_file.image.id}
        filename={data.image.image_file.image.filename_download}
      />
    )}
    <section
      id="harley-street-facelift-hero"
      className="relative w-full min-h-[421px] flex items-center"
    >
      {data?.image && (
        <Image
          src={getReadableImageUrl(
            data?.image?.image_file?.image?.id,
            data?.image?.image_file?.image?.filename_download
          )}
          alt={data?.image?.alt || ""}
          fill
          priority
          className="object-cover z-[-1]" 
          style={{ objectPosition: "50% 50%" }}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
          quality={95}
          fetchPriority="high"
          placeholder="blur"
          blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx4eHRseHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/2wBDAR4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="
        />
      )}
      <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-black/10 z-0" />
      <div className="container py-[140px] relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="hidden md:block" />
          <div className="space-y-8 max-w-[490px]">
            <h1
              className="font-freightBig text-4xl md:text-6xl text-white leading-none"
              dangerouslySetInnerHTML={sanitizeHTML(
                data?.heading?.title || data?.heading?.text || ""
              )}
            />
            <div
              className="space-y-4 text-white font-aileron text-lg mb-12 [&_p]:flex [&_p]:items-center [&_p]:gap-3 [&_img]:w-[30px] [&_img]:h-[30px] [&_img]:flex-shrink-0 [&_ul]:list-disc [&_ul]:pl-5 [&_li]:my-2"
              dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
            />
            {data?.button && (
              <Button asChild>
                <Link href={data?.button?.href || "#"}>
                  {data?.button?.label}
                </Link>
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
