import { Star } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Image from "next/image";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";
import { useState } from "react";

interface GoogleReviewsData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  content_collection?: {
    id: string;
    reviews_id: {
      rating: number;
      author: string;
      comment: string;
    };
  }[];
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text",
  "subheading.title",
  "content_collection.id",
  "content_collection.reviews_id.rating",
  "content_collection.reviews_id.author",
  "content_collection.reviews_id.comment",
] as const;

interface GoogleReviewsProps {
  data?: GoogleReviewsData;
}

export default function GoogleReviews({ data }: GoogleReviewsProps) {
  const [expandedReviews, setExpandedReviews] = useState<string[]>([]);

  const toggleReview = (reviewId: string) => {
    setExpandedReviews(prev => 
      prev.includes(reviewId) 
        ? prev.filter(id => id !== reviewId)
        : [...prev, reviewId]
    );
  };

  return (
    <section id="google-reviews" className="bg-black py-14">
      <div className="max-w-[1080px] mx-auto px-8">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-[900] text-white mb-2">{data?.heading?.title || ''}</h2>
          <div className="flex justify-center gap-1 mb-2">
            {[...Array(4)].map((_, i) => (
              <Star
                key={i}
                className="w-8 h-8 text-yellow-400 fill-yellow-400"
              />
            ))}
            <Star className="w-8 h-8 text-yellow-400 fill-yellow-400/50" />
          </div>
          <p className="text-white text-sm" dangerouslySetInnerHTML={sanitizeHTML(data?.subheading?.text || '')} />
          <Image
            src="/images/Google_big.svg"
            alt="Google"
            width={110}
            height={35}
            className="mx-auto mt-2"
            quality={95}
            loading={"lazy"}
          />
        </div>
        <Carousel className="w-full">
          <CarouselContent>
            {data?.content_collection?.map((review) => (
              <CarouselItem key={review.id} className="md:basis-1/3">
                <div className="bg-[#f4f4f4] p-5 rounded-md h-full">
                  <div className="flex items-center gap-4 mb-4">
                    <div>
                      <h3 className="font-bold text-sm">{review.reviews_id.author}</h3>
                    </div>
                    <Image
                      src="/images/Google_small.svg"
                      alt="Google"
                      width={20}
                      height={20}
                      className="ml-auto"
                      quality={95}
                      loading={"lazy"}
                    />
                  </div>
                  <div className="flex gap-1 mb-4">
                    {[...Array(review.reviews_id.rating || 5)].map((_, i) => (
                      <Star
                        key={i}
                        className="w-4 h-4 text-yellow-400 fill-yellow-400"
                      />
                    ))}
                  </div>
                  <div className="relative">
                    <p className={expandedReviews.includes(review.id) ? "" : "line-clamp-3"}>
                      {review.reviews_id.comment}
                    </p>
                    {review.reviews_id.comment.length > 100 && (
                      <button
                        onClick={() => toggleReview(review.id)}
                        className="text-blue-600 hover:text-blue-800 text-sm mt-2 focus:outline-none"
                      >
                        {expandedReviews.includes(review.id) ? "Read less" : "Read more"}
                      </button>
                    )}
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="hidden md:flex" />
          <CarouselNext className="hidden md:flex" />
        </Carousel>
      </div>
    </section>
  );
}
